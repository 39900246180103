import React from "react";
import styled from "styled-components";

interface CircleProps {
  sections: number;
  filledSections: number;
  content?: React.ReactNode;
  button?: React.ReactNode;
  colorProgressEmpty?: string;
  colorProgressFilled?: string;
  colorBg?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Container = styled.div<{ bgColor: string }>`
    position: relative;
    width: 70px;
    height: 70px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    cursor: pointer;
`

const SvgCircle = styled.svg`
    width: 70px;
    height: 70px;
    transform: rotate(-192deg);
    border-radius: 50%;
`;

const ContentWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const calculateArcPath = (
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const start = polarToCartesian(35, 35, radius, endAngle);
  const end = polarToCartesian(35, 35, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  return [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");
};

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
};

export const CircularProgress: React.FC<CircleProps> = ({
  sections,
  filledSections,
  content,
  button,
  colorBg = '#4C5F72',
  colorProgressEmpty = '#87ADD0',
  colorProgressFilled = "#ECBF02",
  onClick
}) => {
  const progressWidth = 6;
  const progressRadius = 30;
  const outerBorderRadius = 34;
  const innerBorderRadius = 26;
  const borderWidth = 2;

  const totalAngle = 290; // use only 270 from 360 in order to have a gap for button
  const sectionAngle = totalAngle / sections;
  const separatorAngle = 2;

  const centerX = 35;
  const centerY = 35



  return (
   <Container bgColor={colorBg} onClick={onClick}>
     <SvgCircle viewBox="0 0 70 70">
       {/* outer border */}
       <circle
         cx={centerX}
         cy={centerY}
         r={outerBorderRadius}
         stroke="black"
         strokeWidth={borderWidth}
         fill="none"
       />

       {Array.from({ length: sections }).map((_, i) => {
         const startAngle = i * sectionAngle + 45 + separatorAngle / 2;
         const endAngle = (i + 1) * sectionAngle + 45 - separatorAngle / 2;
         const isFilled = i < filledSections;

         const separatorStartAngle = i * sectionAngle + 45;
         const separatorEndAngle = startAngle + separatorAngle;

         return (
           <>
             <path
               key={i}
               d={calculateArcPath(progressRadius, startAngle, endAngle)}
               fill="none"
               stroke={isFilled ? colorProgressFilled : colorProgressEmpty}
               strokeWidth={progressWidth}
               strokeLinecap="butt"
             />
             <path
               key={`separator-${i}`}
               d={calculateArcPath(progressRadius, separatorStartAngle, separatorEndAngle)}
               fill="none"
               stroke="black"
               strokeWidth={progressWidth}
               strokeLinecap="butt"
             />
           </>
         );
       })}

       {/* inner border */}
       <circle
         cx={centerX}
         cy={centerY}
         r={innerBorderRadius}
         stroke="black"
         strokeWidth={borderWidth}
         fill="none"
       />
     </SvgCircle>

     {content && <ContentWrapper>{content}</ContentWrapper>}
     {button}
   </Container>
  );
};
