import { useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';

import { WebApp } from '@vkruglikov/react-telegram-web-app/lib/core/twa-types';
import {
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CountUp from 'react-countup';
import ReactGA from 'react-ga4';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import styled, { css } from 'styled-components';
import pigeonsAnimation from '../../animations/pigeons.json';
import { NotificationsModal } from '../../components/Notification';
import { Priests } from '../../components/Priests';
import { Relics } from '../../components/Relics';
import { ControlContext } from '../../contexts/control';
import { UserContext } from '../../contexts/user';
import PopeBase from '../../images/durov_base.png';
import TONPlate from '../../images/plate.png';
import Pulpit from '../../images/pulpit.png';
import RelicBox from '../../images/relics/relic-box.png';
import { ReactComponent as Blessings } from '../../images/stars.svg';
import { Button } from "../../shared/components/button";
import { StarsAnimation } from '../../shared/components/stars-animation';
import { Message, RelicData } from '../../types';
import { formatFaithValue } from "../../utils";
import { Boosts } from "./boosts";
import { ChestProgress } from "./chest-progress";

const RelicSpriteList = ['green', 'red', 'violet', 'blue', 'gold', 'diamond'];
let relicSprites: { [id: string]: string } = {};

for (var s of RelicSpriteList) {
  relicSprites[s] = require(`../../images/relics/${s}.png`);
}

interface FaithData {
  epoch_start: string | null;
  pending_balance: number;
  pending_sec: number | null;
  total_sec: number | null;
  ton_rate: number;
  ton_direction: number;
}

interface FaithMessage extends Message {
  data: FaithData;
}

interface RelicMessage extends Message {
  data: Array<RelicData>;
}

const LottieL = lazy(() => import('lottie-react'));

const PendingCounter = ({
  pending,
  total_unlock,
  total_time,
}: {
  pending: number;
  total_unlock: number;
  total_time: number;
}) => {
  const rate = total_unlock / total_time;
  let digits = 0,
    cur = rate;
  while (cur < 1) {
    digits++;
    cur *= 10;
  }

  const [part, frac] = pending.toFixed(digits).split('.');

  const addPadding = useCallback(
    (num: number) => {
      return num.toString().padStart(digits, '0');
    },
    [digits]
  );

  return (
    <>
      <Blessings height={18} fill='white' />
      <CountUp
        duration={5}
        useGrouping={false}
        separator={''}
        preserveValue={true}
        end={parseInt(part)}
      />
      <span style={{ fontFamily: 'Oswald' }}>.</span>
      <CountUp
        duration={5}
        useGrouping={false}
        separator={''}
        preserveValue={true}
        end={parseInt(frac)}
        formattingFn={addPadding}
        style={{ fontSize: '100%' }}
      />
    </>
  );
};

interface TONData {
  rate: number;
  grow: boolean;
}

const ActionButtonWrapper = styled.div`
    position: relative;
    padding: 0 12px 16px;
    flex-shrink: 0;
`

const ActionButton = styled(Button).attrs({ $size: 'xl', $shine: true })<{ $disabled?: boolean, $progress?: number }>`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  ${props => props.$disabled && css`
      cursor: default;
  `}

  ${props => props.$progress !== undefined && css`
      background: linear-gradient(
      to right,
      #2F86B3 ${props.$progress}%,
      #5d8295 ${props.$progress}%
      );
  `}
`

type Props = {
  navigateToRituals: () => void;
}

export const BlessingPage = ({ navigateToRituals }: Props) => {
  const [initDataUnsafe] = useInitData();
  const [isDisabled, setIsDisabled] = useState(true);
  const [progress, setProgress] = useState(false);
  const [unlock, setUnlock] = useState<number>();
  const [pending, setPending] = useState<number | undefined>(undefined);
  const [stopTime, setStopTime] = useState<string | undefined>(undefined);
  const [alarm, setAlarm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pctProgress, setPctProgress] = useState(0);
  const [prayTime, setPrayTime] = useState<number>();
  const [celebration, setCelebration] = useState(false);
  const [wavesVisible, setWavesVisible] = useState(false);
  const [pope, setPope] = useState<HTMLDivElement>();
  const [popeMoving, setPopeMoving] = useState(true);
  const [relicsShow, setRelicsShow] = useState(false);
  const [coinsShow, setCoinsShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const [notificationText, setNotificationText] = useState('');
  const [relic, setRelic] = useState<RelicData>();
  const [tonData, setTonData] = useState<TONData>();

  const tickHandle = useRef<ReturnType<typeof setTimeout>>();

  const user = useContext(UserContext);
  // const { mute, setSound, startStopSound, sound, player } =
  //   useContext(ControlContext).music;

  const { version, mode } = useContext(ControlContext).game;

  const webApp: WebApp = useWebApp();

  const { sendJsonMessage, readyState, lastJsonMessage } = useWebSocket<
    FaithMessage | RelicMessage
  >(process.env.REACT_APP_WSS_ENDPOINT!, {
    filter: (m) => ['faith', 'relic'].indexOf(JSON.parse(m.data).method) >= 0,
    share: true,
    shouldReconnect: () => true,
  });

  // enable vibration support
  // @ts-ignore
  navigator.vibrate =
    navigator.vibrate ||
    navigator.webkitVibrate ||
    navigator.mozVibrate ||
    navigator.msVibrate;

  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !!!user || user.id === 0) return;
    sendJsonMessage({ method: 'faith' });
    sendJsonMessage({ method: 'relic:list' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, readyState]);

  useEffect(() => {
    if (!lastJsonMessage || lastJsonMessage.error) return;

    setLoading(false);

    if (lastJsonMessage.method === 'relic') {
      const data = lastJsonMessage.data as Array<RelicData>;
      if (data.length) {
        setRelic(data[0]);
      } else {
        setRelic(undefined);
        setRelicsShow(false);
      }
    } else if (lastJsonMessage.method === 'faith') {
      const data = lastJsonMessage.data as FaithData;

      setTonData({ rate: data.ton_rate, grow: data.ton_direction === 1 });

      if (!data.epoch_start) {
        setProgress(false);
        setStopTime('');
        setAlarm(false);
        setPending(0);
        setUnlock(undefined);
        setWavesVisible(false);
        setPrayTime(undefined);
        setIsDisabled(false);
        if (tickHandle.current) {
          clearTimeout(tickHandle.current);
          tickHandle.current = undefined;
        }
      } else if (
        data.epoch_start &&
        data.pending_sec &&
        data.total_sec &&
        data.pending_sec > 0
      ) {
        if (tickHandle.current) {
          clearTimeout(tickHandle.current);
          tickHandle.current = undefined;
        }

        setAlarm(false);
        setWavesVisible(true);
        setPrayTime(data.total_sec);

        const fullInterval = data.total_sec * 1000;

        const tick = (left: number) => {
          if (!left || left < 0) {
            sendJsonMessage({ method: 'faith', from: 'alarm' });
            return;
          }

          let p, restHr, restMn;

          if (left >= 0) {
            p = Math.ceil(((fullInterval - left) / fullInterval) * 100);

            restHr = Math.floor(left / 1000 / 3600);
            restMn = Math.ceil((Math.floor(left / 1000) - restHr * 3600) / 60);

            let _sLeft = left / 1000;
            restHr = Math.floor(_sLeft / 3600);
            _sLeft %= 3600;
            restMn = Math.floor(_sLeft / 60);
            // add 1 minute to the rest time if 0 hours and 0 minutes left
            if (!restHr && !restMn) {
              restMn = 1;
            }
          } else {
            p = 100;
            restHr = '0';
            restMn = '0';
          }

          setPctProgress(p);
          setPending(
            ((fullInterval - left) / fullInterval) * data.pending_balance
          );
          setStopTime(
            `${('0' + restHr).slice(-2)}h ${('0' + restMn).slice(-2)}m`
          );

          if (p < 100) {
            tickHandle.current = setTimeout(
              () => tick(left >= 1000 ? left - 1000 : 0),
              1000
            );
          } else {
            setPending(data.pending_balance);
            sendJsonMessage({ method: 'faith', from: 'alarm' });
          }
        };

        setUnlock(data.pending_balance);
        tick(data.pending_sec * 1000 - 300);

        setProgress(true);
        setIsDisabled(true);
      } else if (data.epoch_start && data.pending_sec === 0) {
        setStopTime('');
        setWavesVisible(false);
        setProgress(false);
        setIsDisabled(false);
        setUnlock(undefined);
        setPending(data.pending_balance);
        setAlarm(true);
        setPrayTime(undefined);

        if (tickHandle.current) {
          clearTimeout(tickHandle.current);
          tickHandle.current = undefined;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  useEffect(() => {
    if (!celebration) return;

    if (webApp && webApp.HapticFeedback) {
      webApp.HapticFeedback.notificationOccurred("error");
    } else if (navigator.vibrate) {
      // vibration API supported
      navigator.vibrate([150, 70, 80]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [celebration]);

  // useEffect(() => {
  //   console.log("alarm fired", alarm, progress)
  //   if (alarm === undefined || progress === undefined || sound === undefined || !player || !setSound) return;

  //   if (progress) {
  //     if (sound !== '/music/pray.mp3') {
  //       setSound('/music/pray.mp3');
  //     }

  //   } else if (alarm) {
  //     if (sound !== '/music/bg.mp3') {
  //       setSound('/music/bg.mp3');
  //     }

  //   } else {
  //     if (sound !== '/music/bg.mp3') {
  //       setSound('/music/bg.mp3');
  //     }
  //   }
  // }, [alarm, progress, sound, player, setSound]);

  const handleClick = () => {
    if (isDisabled) return;

    if (!alarm && !progress) {
      setIsDisabled(true);
      setLoading(true);

      if (webApp && webApp.HapticFeedback) {
        webApp.HapticFeedback.notificationOccurred('success');
      } else if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate([150]);
      }

      sendJsonMessage({ method: 'pray' });

      ReactGA.event('pray_start');
    } else {
      setIsDisabled(true);
      setLoading(true);
      setCelebration(true);

      setTimeout(() => setCelebration(false), 10000);
      sendJsonMessage({ method: 'bless' });

      ReactGA.event('pray_claim', { value: pending });
    }
  };

  const popeRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) setPope(node);
  }, []);

  useEffect(() => {
    if (!pope || !user.id) return;

    setTimeout(() => pope.style.opacity = "1", 1000);

    // pope.addEventListener(
    //   'animationend',
    //   () => {
    //     setTimeout(() => setPopeMoving(false), 1000);
    //   },
    //   false
    // );

    // pope.addEventListener(
    //   'animationstart',
    //   () => {
    //     setPopeMoving(true);
    //   },
    //   false
    // );
  }, [pope, user.id]);

  const relicAction = useCallback(
    (relicType: string, action: string) => {
      setRelic(undefined);
      sendJsonMessage({ method: 'relic:open', action: action });
      setRelicsShow(false);

      if (action === 'claim') {
        if (relicType === 'ton' || relicType === 'ton-nft') {
          setCoinsShow(true);
          setNotificationText(
            'You will receive your reward to your connected wallet in a few minutes.'
          );
        }

        if (relicType === 'boost-pray') {
          setNotificationText('Your next prayer will be faster.');
        }

        if (relicType === 'boost-faith') {
          setNotificationText(
            'You will receive more faith on the next prayer.'
          );
        }

        if (
          relicType === 'ton' ||
          relicType === 'ton-nft' ||
          relicType === 'boost-pray' ||
          relicType === 'boost-faith'
        ) {
          setNotificationShow(true);
        }
      }
    },
    [sendJsonMessage]
  );

  // useEffect(() => {
  //   if (!popeMoving && wavesVisible) {
  //     document.getElementById('waves-anim')!.style.display = 'block';
  //   } else {
  //     document.getElementById('waves-anim')!.style.display = 'none';
  //   }
  // }, [popeMoving, wavesVisible]);

  if (!initDataUnsafe) return <></>;

  const renderButtonContent = () => {
    if (loading) return <div className="btn loader"/>;

    if (progress) {
      return <>
        <div>
          Growing faith{' '}
          <PendingCounter pending={pending!} total_time={prayTime!} total_unlock={unlock!}/>
        </div>
        <div style={{fontSize: 12, marginLeft: 'auto'}}>{stopTime}</div>
      </>
    }

    if (alarm) return <div>Increase faith <Blessings height={18} fill='white'/> +{pending}</div>

    return <>Start praying</>
  }

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      <ChestProgress onStart={navigateToRituals} ritualsCount={user.rituals} />
      <Relics
        relic={relic}
        show={relicsShow}
        onClose={() => setRelicsShow(false)}
        onAction={relicAction}
      />

      <NotificationsModal
        show={notificationShow}
        coins={coinsShow}
        title={'Congratulations!'}
        text={<span>{notificationText}</span>}
        btnName="OK"
        onClose={() => {
          setCoinsShow(false);
          setNotificationShow(false);
          setNotificationText('');
        }}
        onSuccess={() => {
          setCoinsShow(false);
          setNotificationShow(false);
          setNotificationText('');
        }}
      />

      {relic && (
        <div className="relic-box" onClick={() => setRelicsShow(true)}>
          <img src={RelicBox} alt="" />
          <img
            style={{ display: 'none' }}
            src={relicSprites[relic.sprite]}
            alt=""
          />
        </div>
      )}

      {celebration && (
        <Suspense>
          <LottieL
            animationData={pigeonsAnimation}
            loop={false}
            className="bg-animation"
          />
        </Suspense>
      )}

      {wavesVisible && <div className="ton-waves"></div>}

      {tonData && (
        <div className="ton-plate">
          <span className="ton-rate">${tonData.rate.toPrecision(3)}</span>
          <img style={{ width: '100%' }} src={TONPlate} alt="" />
        </div>
      )}

      <div
        className={`pope game-ver1`}
        style={{opacity: 0}}
        ref={popeRef}
        onClick={() => {
          if (mode === 0) {
            setCelebration(true);
            // setCoinsShow(true);
            setTimeout(() => setCelebration(false), 10000);
          }
          // setWavesVisible(true);
        }}
      >
        {/* <img src={PopePrison} alt="" /> */}

        <img src={PopeBase} alt="" />
        {[
          'body',
          'pants',
          'belt',
          'coat',
          'face',
          'hat',
          'nimb',
          'boots',
          'shoulder',
          'rod',
          'left_hand',
          'right_hand',
        ].map((slot) => {
          if (user.items?.[slot]) {
            return <img src={`/items/${user.items[slot]}?v1`} alt="" />;
          } else {
            return <></>;
          }
        })}
      </div>

      {(user.boosts.length > 0 || user.nfts.length > 0) && (
        <Boosts boosts={user.boosts} nfts={user.nfts} />
      )}

      {/* <div className="relics" onClick={() => setRelicsShow(true)}>
        <img src={PastaMedal} alt="" />
      </div> */}

      <div className="pulpit">
        <Priests nfts={user.nfts} />
        <img
          src={Pulpit}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 1000,
          }}
          alt=""
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 0 }}>
        {/* <div
          className={'settings' + (mute ? ' off' : '')}
          onClick={startStopSound}
        >
          <Sound height={18} />
        </div> */}
        <div className="blessings blessings-faith">
          <Blessings height={18} />{' '}
          {user.id > 0 ? (
            <CountUp
              duration={2}
              separator={' '}
              preserveValue={true}
              formattingFn={formatFaithValue}
              end={user.balance}
            />
          ) : (
            '...'
          )}{' '}
          FAITH
        </div>
      </div>
      {/* {tonData && (
        <div className={"ton-rate " + (tonData.grow ? 'up' : 'down')}>
          ${tonData.rate.toFixed(2)}
        </div>
      )} */}

      <div style={{ flex: '1 1 0', zIndex: -20000 }}></div>

      <ActionButtonWrapper>
        {progress && !loading && <StarsAnimation />}
        <ActionButton
          onClick={handleClick}
          $variant={!progress && alarm ? 'green' : 'blue'}
          $disabled={isDisabled}
          $progress={!loading && progress ? pctProgress : undefined}
        >
          {renderButtonContent()}
        </ActionButton>
      </ActionButtonWrapper>
    </div>
  );
};
