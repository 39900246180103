import styled from "styled-components";

const FOOTER_HEIGHT = 60;

export const PageContainer = styled.div`
    position: relative;
    padding: 12px 12px 0;
    background: var(--tgm-theme-bg-color);
    color: var(--tgm-theme-text-color) !important;
    display: flex;
    flex-direction: column;
    height: calc(var(--dvh,100dvh) - ${FOOTER_HEIGHT}px - env(safe-area-inset-bottom));
    box-sizing: border-box;
    overflow-y: auto;
`
