import { useCloudStorage } from "@vkruglikov/react-telegram-web-app";
import React, {useCallback, useEffect, useState} from "react";
import useWebSocket from "react-use-websocket";
import styled from "styled-components";
import { NotificationsModal } from "../../components/Notification";
import chestImgUrl from "../../images/chest_small.png";
import raysGoldImageUrl from "../../images/rays_gold.svg";
import { Button } from "../../shared/components/button";
import { CircularProgress } from "../../shared/components/circular-progress";
import { Message, UserRitualsCountType } from "../../types";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 24px 24px 12px;
    background: linear-gradient(-40.7deg, rgba(0, 0, 0, 0) 51.72%, rgba(0, 0, 0, 0.562479) 74.41%, #000000 100.59%);
`

const StyledButton = styled(Button)`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
`

const ChestImageWrapper = styled.div`
    position: relative;
`

const Rays = styled.div`
    width: 98px;
    height: 98px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-image: url(${raysGoldImageUrl});
    z-index: -1;
`

type Props = {
  onStart: () => void;
  ritualsCount: UserRitualsCountType;
}

export const ChestProgress = ({ onStart, ritualsCount }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const completed = ritualsCount.claimed >= ritualsCount.thr;
  const filledSections = completed ? 0 : ritualsCount.claimed;

  const cloudStorage = useCloudStorage();

  const { sendJsonMessage, lastJsonMessage } = useWebSocket<Message>(process.env.REACT_APP_WSS_ENDPOINT!, {
    filter: (m) => ['user'].includes(JSON.parse(m.data).method),
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (isClaiming && lastJsonMessage) {
      setIsClaiming(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  const handleClaim = useCallback(() => {
    sendJsonMessage({ "method": "ritual_award" })
    setIsClaiming(true)
  }, [sendJsonMessage])

  const handleStart = () => {
    cloudStorage.getItem('chestProgressInfoShown').then((shown) => {
      if (!shown) {
        setShowModal(true);
        cloudStorage.setItem('chestProgressInfoShown', 'true');
      } else {
        onStart();
      }
    });
  }

  return <Container>
    <CircularProgress
      sections={ritualsCount.thr}
      filledSections={filledSections}
      onClick={completed ? handleClaim : handleStart}
      content={(
        <ChestImageWrapper>
          <img style={{display: 'block'}} alt='' src={chestImgUrl} width={48} height={44}/>
          {completed && <Rays />}
        </ChestImageWrapper>
      )}
      button={completed ? (
          <StyledButton
            $variant={isClaiming ? 'black' : 'blue'}
            $size='xs'
            disabled={isClaiming}
            style={{ width: isClaiming ? 60 : undefined }}
          >
            {isClaiming ? <span style={{ display: 'inline-block'}} className="line loader" /> : 'Claim'}
          </StyledButton>
        ) : (
          <StyledButton $variant='blue' $size='xs'>Start</StyledButton>
      )}
      colorProgressEmpty={completed ? 'rgba(76, 95, 114, 0.2)' : undefined}
      colorBg={completed ? 'rgba(175, 173, 208, 0.5)' : undefined}
    />

    <NotificationsModal
      show={showModal}
      coins={false}
      title={'Earn your Treasure!'}
      btnName="Go to Rituals"
      text={
        <span>
          Complete {ritualsCount.thr} Rituals to earn your Treasure Chest!
        </span>
      }
      onClose={() => setShowModal(false)}
      onSuccess={onStart}
    />
  </Container>
}
