
import { useContext } from "react";
import { ControlContext } from "../contexts/control";
import { TaskContext } from "../contexts/task";
import { UserContext } from "../contexts/user";
import { ReactComponent as Chalice } from "../images/chalice.svg";
import { ReactComponent as Blessing } from "../images/hand.svg";
import { ReactComponent as Parish } from "../images/parish.svg";
import { ReactComponent as Shop } from "../images/shop.svg";
import { ReactComponent as Sword } from "../images/sword.svg";


type ChangeFunc = (x: number) => void;

export const TaskBar = ({activeItem, onChange}: {activeItem: number, onChange: ChangeFunc}) => {

  const { referrals_left, has_wallet } = useContext(UserContext);
  const { tasks_available } = useContext(TaskContext);
  const { mode, version } = useContext(ControlContext).game;

  if (mode < 0) return <></>;

  return (
    <footer className="taskbar">
      <div
        className={activeItem === 0 ? 'item active' : 'item'}
        onClick={() => onChange(0)}
      >
        <div>{mode === 0 ? <Blessing width={12} /> : <Sword width={24} />}</div>
        <div>{mode === 0 ? 'Pray' : 'Battle'}</div>
      </div>
      {version >= 3 && <div
        className={activeItem === 3 ? 'item active' : 'item'}
        onClick={() => onChange(3)}
      >
        <div>
          <Shop width={20} height={20} />
        </div>
        <div>Shop</div>
      </div>}
      {mode === 0 && <div
        className={activeItem === 1 ? 'item active' : 'item'}
        onClick={() => onChange(1)}
      >
        <div>
          <Chalice width={20} height={20} />
        </div>
        {tasks_available > 0 && (
          <div className="circle">{tasks_available + (has_wallet ? 0 : 1)}</div>
        )}
        <div>Rituals</div>
      </div>}
      <div
        className={activeItem === 2 ? 'item active' : 'item'}
        onClick={() => onChange(2)}
      >
        <div>
          <Parish width={20} />
        </div>
        {/* {referrals_left > 0 && <div className="circle">{referrals_left}</div>} */}
        <div>Invite</div>
      </div>
    </footer>
  );
}
