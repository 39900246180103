import { Space } from "../../shared/components/space";
import {StoreProductCurrency, StoreProductItem} from "../../types";
import { ReactComponent as StarTg } from "../../images/star_tg.svg";
import { ReactComponent as Blessings } from '../../images/stars.svg';

type Props = {
  product: StoreProductItem;
  fontSize: number;
  iconSize: number;
}

export const ProductPrice = ({ product, fontSize, iconSize }: Props) => {
  const renderIcon = () => {
    if (product.currency === StoreProductCurrency.STARS) {
      return <StarTg height={iconSize} />;
    }
    if (product.currency === StoreProductCurrency.FAITH) {
      return <Blessings height={iconSize} fill='white' />;
    }
    return null;
  }

  return <Space size={2} align={'center'}>
    {renderIcon()}
    <span style={{
      fontWeight: 600,
      fontSize,
      color: '#FFFFFF',
      fontFamily: 'Open Sans'
    }}>{product.cost}</span>
  </Space>
}
