import { useContext } from "react";
import styled, { css } from "styled-components";
import { UserContext } from "../../contexts/user";
import raysImageUrl from '../../images/rays.svg';
import { Button } from "../../shared/components/button";
import { Modal } from "../../shared/components/modal";
import { StoreProductCurrency, StoreProductItem } from "../../types";
import { ProductButtonContent } from "./product-button-content";
import { ProductPrice } from "./product-price";

const StyledModal = styled(Modal)<{ $green?: boolean }>`
  ${({ $green }) => $green && css`
    background: #324D30;
  `}
`

const Content = styled.div<{ $withRays?: boolean }>`
  padding: 36px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $withRays }) => $withRays && css`
    background-size: cover;
    background: url(${() => raysImageUrl}) no-repeat center calc(-85px);
  `}
`

const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  text-align: center;
`

const Image = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`

const Title = styled.div`
  color: white;
  font-family: "Oswald";
  font-size: 32px;
  line-height: 47px;
  font-weight: 600;
  margin-bottom: 12px;
`

const Description = styled.div`
  color: white;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
`

type Props = {
  isOpen: boolean;
  onClose: () => void;
  item: StoreProductItem;
  onBuy: (item: StoreProductItem) => void;
  onEquip: (item: StoreProductItem) => void;
  onUnequip: (item: StoreProductItem) => void;
}

export const ProductModal = ({ item, isOpen, onClose, onBuy, onEquip, onUnequip }: Props) => {
  const user = useContext(UserContext);

  const equipped = item.bought && item.active;

  const handleClick = () => {
    if (item.bought && !item.active) {
      onEquip(item)
    }

    if (item.bought && item.active) {
      onUnequip(item)
    }

    if (!item.bought) {
      onBuy(item)
    }
  }

  const isDisabled = () => {
    if (!item.bought && item.currency === StoreProductCurrency.FAITH && item.cost > (user.balance || 0)) {
      return true;
    }
    return false
  }

  return <StyledModal isOpen={isOpen} onClose={onClose} $green={equipped}>
    <Content $withRays={!item.bought}>
      <ImageContainer>
        <Image src={`/items/${item.preview}`} />
      </ImageContainer>
      <Title>
        {item.title}
      </Title>
      {item.description &&
        <Description>
          {item.description}
        </Description>
      }
      {!item.bought && <ProductPrice product={item} iconSize={32} fontSize={24}/>}

      <Button
        $variant={equipped ? 'green' : 'blue'}
        onClick={handleClick}
        style={{ width: '100%', marginTop: 20 }}
        $size='xl'
        disabled={isDisabled()}
      >
        <ProductButtonContent product={item} userBalance={user.balance} />
      </Button>
    </Content>
  </StyledModal>
}
