import { useContext, useEffect, useState } from 'react';
import { ControlContext } from '../contexts/control';
import { UserContext } from '../contexts/user';
import { ReactComponent as Close } from '../images/close.svg';
import RelicStars from '../images/relic-stars-fs8.png';
import RelicBox from '../images/relics/relic-box.png';
import { ReactComponent as Blessings } from '../images/stars.svg';
import { RelicData } from '../types';
import './Relics.css';
import { getNFTItem, MaybeNFTItem } from './nft/nftItem';

const titles: { [id: string]: any } = {
  'boost-faith': (
    <span>
      You've got <strong>Boost!</strong>
      {/* <strong>
        <Blessings height={30} />
        FAITH
      </strong> */}
    </span>
  ),
  'boost-pray': (
    <span>
      You've got <strong>Boost!</strong>
    </span>
  ),
  faith: (
    <span>
      You've got
      <strong>
        <Blessings height={30} />
        FAITH!
      </strong>
    </span>
  ),
  overall: (
    <span>
      Multiply{' '}
      <strong>
        <Blessings height={30} />
        FAITH!
      </strong>
    </span>
  ),
  ton: (
    <span>
      You've got <strong>Treasure!</strong>
    </span>
  ),
  'ton-nft': (
    <span>
      You've got <strong>Treasure!</strong>
    </span>
  ),
};

const Description = ({
  relicData,
  wallet,
  nft,
}: {
  relicData: RelicData;
  wallet: string | null;
  nft: MaybeNFTItem;
}) => {
  const descriptions: { [id: string]: any } = {
    'boost-faith': (
      <span>
        Boost your{' '}
        <strong>
          <Blessings height={18} />
          FAITH
        </strong>{' '}
        generation by <strong>x{relicData.args.value}</strong> for the next
        prayer
      </span>
    ),
    'boost-pray': (
      <span>
        Speed up your next prayer
        <br />
        by <strong>x{relicData.args.value}</strong>!
      </span>
    ),
    faith: (
      <span>
        Receive{' '}
        <strong>
          <Blessings height={18} />
          {relicData.args.value} FAITH
        </strong>
      </span>
    ),
    overall: (
      <span>
        Multiply all your{' '}
        <strong>
          <Blessings height={18} />
          FAITH
        </strong>{' '}
        by <strong>x{relicData.args.value}</strong>
      </span>
    ),
    ton: wallet ? (
      <span>
        Receive{' '}
        <strong>
          {relicData.args.value &&
            relicData.args.value /
              Math.pow(10, relicData.args.decimals || 9)}{' '}
          {relicData.args.asset}
        </strong>{' '}
        to your connected wallet{' '}
        <strong>
          {wallet?.slice(0, 4)}...{wallet?.slice(-4)}
        </strong>
      </span>
    ) : (
      <span>
        Connect wallet to receive{' '}
        <strong>
          {relicData.args.value &&
            relicData.args.value /
              Math.pow(10, relicData.args.decimals || 9)}{' '}
          {relicData.args.asset}
        </strong>
      </span>
    ),
    'ton-nft': wallet ? (
      <span>
        Receive <strong>{nft ? nft.name : 'NFT'}</strong> to your connected
        wallet{' '}
        <strong>
          {wallet?.slice(0, 4)}...{wallet?.slice(-4)}
        </strong>
      </span>
    ) : (
      <span>
        Connect wallet to receive <strong>{nft ? nft.name : 'NFT'}</strong>
      </span>
    ),
  };

  return descriptions[relicData.type];
};


export function Relics({
  show,
  relic,
  onClose,
  onAction
}: {
  relic: RelicData | undefined;
  show: boolean;
  onClose: () => void;
  onAction: (relicType: string, action: string) => void;
}) {

  const { balance, wallet } = useContext(UserContext);
  const { setScreenIdx } = useContext(ControlContext);

  const [needWallet, setNeedWallet] = useState(true);
  const [nft, setNft] = useState<MaybeNFTItem>(undefined);

  useEffect(() => {
    if (relic === undefined) return;

    setNeedWallet((relic.type === 'ton' || relic.type === 'ton-nft') && (wallet === null || wallet === ''));
  }, [wallet, relic])

  useEffect(() => {
    if (relic === undefined || relic.type !== 'ton-nft') return;

    (async () => {
      if (relic.args.nft_address !== undefined) {
        setNft(await getNFTItem(relic.args.nft_address));
      }
    })();
  }, [relic])

  if (!relic || !show) return <></>;

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    if ((e.target as HTMLElement).classList[0] !== "relics__overlay") {
      return;
    }

    e.stopPropagation();
    onClose();
  }

  return (
    <div className="relics__overlay" onClick={handleClick}>
      <div className="relics__wrapper">
        <div
          style={{
            position: 'absolute',
            cursor: 'pointer',
            right: '12px',
            top: '15px',
            width: '28px',
            height: '28px',
          }}
          onClick={() => onClose()}
        >
          <Close width={28} />
        </div>
        <div className="relics__box">
          <div className="bg">&nbsp;</div>
          {relic.type === 'ton' ? (
            <img
              style={{ mixBlendMode: 'normal' }}
              src={RelicBox}
              alt=""
            />
          ) : relic.type === 'ton-nft' ? (
            <img
              style={{ mixBlendMode: 'normal', top: 0, left: '5vw', boxShadow: '0 0 15px gold' }}
              src={nft ? nft.image : RelicBox}
              alt=""
            />
          ) : (
            <img src={RelicStars} style={{ left: '5vw', top: 0 }} alt="" />
          )}
        </div>

        <div className="relics__title">{titles[relic.type]}</div>

        <div className="relics__description">
          <Description relicData={relic} wallet={wallet} nft={nft} />
        </div>

        <div className="relics__buttons">
          <div className="button__wrapper">
            {balance >= relic.cost && !needWallet && (
              <button
                className="open"
                onClick={() => onAction(relic.type, 'claim')}
              >
                {relic.type === 'ton' || relic.type === 'ton-nft'
                  ? 'OPEN'
                  : 'GET'}{' '}
                {relic.cost > 0 ? (
                  <span>
                    for <Blessings height={24} />
                    {relic.cost} FAITH
                  </span>
                ) : (
                  <span>for FREE</span>
                )}
              </button>
            )}

            {balance >= relic.cost && needWallet && (
              <button className="open" onClick={() => setScreenIdx(1)}>
                Connect wallet
              </button>
            )}

            {balance < relic.cost && (
              <button
                className="open disabled"
                onClick={() => setScreenIdx(1)}
                style={{ fontSize: '87.5%' }}
              >
                You need <strong>{relic.cost} FAITH</strong> to open this Relic
                <br />
                Grow it with prayers and rituals
              </button>
            )}
          </div>
        </div>

        <div className="relics__reject">
          Or, you can{' '}
          <a
            href="#"
            className="reject"
            onClick={() => onAction(relic.type, 'reject')}
          >
            leave it to the Church
          </a>
        </div>
      </div>
    </div>
  );
}
