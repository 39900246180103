import { Suspense, lazy, useContext, useEffect, useState } from "react";
import rewardAnimation from '../animations/reward.json';
import { UserContext } from "../contexts/user";

import "./FaithReceived.css";

const LottieL = lazy(() => import('lottie-react'));

export function FaithReceived() {
  const [oldBalance, setOldBalance] = useState(0);
  const [diff, setDiff] = useState(0);
  const [show, setShow] = useState(false);

  const { balance } = useContext(UserContext);

  useEffect(() => {
    if (balance && oldBalance !== 0) {
      const _diff = balance - oldBalance;

      if (_diff > 0) {
        setDiff(_diff);
        setShow(true);

        setTimeout(() => {
          setShow(false);
        }, 1500);
      }
    }

    setOldBalance(balance);
  }, [balance])

  if (!show) return null;

  return (
    <div className="faith-received" onClick={() => setShow(false)}>
      <Suspense>
        <LottieL
          animationData={rewardAnimation}
          loop={true}
          className="reward-animation"
        ></LottieL>
      </Suspense>
      <div className="faith-note">+{diff}</div>
    </div>
  );
}
