import {PropsWithChildren} from "react";
import styled from "styled-components";

export const StyledPopoverContent = styled.div`
    padding: 16px;
    border-radius: 16px;
    background-color: #000000;
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    letter-spacing: -0.02em;
`

export const PopoverContent = ({ children, className }: PropsWithChildren & { className?: string}) => {
  return <StyledPopoverContent className={`popover__content left ${className || ''}`}>
    {children}
  </StyledPopoverContent>
}

export const Popover = ({ children }: PropsWithChildren) => {
  return <div className="popover__wrapper">
    {children}
  </div>
}
