import styled, {css} from "styled-components";
import { StoreProductItem } from "../../types";
import raysImageUrl from '../../images/rays.svg'
import { ProductButtonContent } from "./product-button-content";

type Props = {
  product: StoreProductItem;
  onClick: () => void;
  loading?: boolean;
}

const Container = styled.div<{ $equipped?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 240px;
  box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 16px;
  box-shadow: 0px 0px 0px 2px #40566C inset;
  background-color: #29445A;
  
  font-family: "Open Sans";
  cursor: pointer;
    
  ${({ $equipped }) => $equipped && css`
    background-color: #324D30;
    box-shadow: 0px 0px 0px 2px #436C40 inset;
  `}
`

const Content = styled.div<{ $withRays?: boolean }>`
  flex-grow: 1;
  padding: 8px 12px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
    
  ${({ $withRays }) => $withRays && css`
    background: url(${() => raysImageUrl}) no-repeat center center;
  `}
`

const ImageContainer = styled.div`
  width: 150px; 
  height: 96px;
  overflow: hidden; 
  position: relative;
  text-align: center;
`

const Image = styled.img`
  width: auto; 
  height: 100%; 
  object-fit: cover; 
  object-position: 50% 50%;
`

const Description = styled.div`
  margin-top: auto;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Footer = styled.div<{ $green?: boolean }>`
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: auto;
  width: 100%;
  background-color: #40566C;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
    
  font-weight: 600;
  font-size: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  ${({ $green }) =>
    $green &&
    css`
      background: #436C40;
    `}
`

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
`

export const ProductCard = ({ product, onClick, loading }: Props) => {
  const equipped = product.bought && product.active;

  return <Container onClick={loading ? () => {} : onClick} $equipped={equipped}>
    <Content $withRays={!equipped}>
      <Title>
        {product.title}
      </Title>

      <ImageContainer>
        <Image src={`/items/${product.preview}`} />
      </ImageContainer>

      <Description>
        {product.description}
      </Description>
    </Content>
    <Footer $green={equipped}>
      <ProductButtonContent product={product} loading={loading} showPrice />
    </Footer>
  </Container>
}
