import {Space} from "../../shared/components/space";
import {StoreProductCurrency, StoreProductItem} from "../../types";
import {ProductPrice} from "./product-price";
import { ReactComponent as CheckCircle } from "../../images/check_circle.svg";

type Props = {
  product: StoreProductItem;
  loading?: boolean;
  userBalance?: number;
  showPrice?: boolean;
}

export const ProductButtonContent = ({ product, loading, showPrice, userBalance }: Props) => {
  const equipped = product.bought && product.active;

  if (loading) {
    return <div className="btn loader"/>
  }

  if (product.bought && !product.active) {
    return <>Equip</>
  }

  if (equipped) {
    return (
      <Space size={4} align='center' justify='center'>
        <CheckCircle fill='#58994E' style={{ border: '1px solid black', borderRadius: '50%' }} />
        <span>Equipped</span>
      </Space>
    )
  }

  if (product.currency === StoreProductCurrency.FAITH) {
    if (showPrice) {
      return <ProductPrice product={product} iconSize={24} fontSize={16} />
    }
    return product.cost > (userBalance || 0) ? <>Not enough FAITH</> : <>Buy</>
  }

  return null;
}
