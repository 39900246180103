import {ReactNode, useRef} from "react";
import styled from "styled-components";
import { ReactComponent as Close } from '../../../images/close.svg';

const Overlay = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  width: 100vw;
  height: var(--dvh);

  background: #19212Ae6;

  z-index: 199995;
`

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100vw;
  height: auto;

  color: #fff;
  border-radius: 24px 24px 0 0;
  background-color: #273747;

  z-index: 200000;
`

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
};

export const Modal = ({ isOpen, onClose, children, className }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    // if not inside the container ref - close the modal
    if (!containerRef.current?.contains(e.target as Node)) {
      e.stopPropagation();
      onClose();
    }
  }

  if (!isOpen) return null;

  return <Overlay onClick={handleClick}>
    <Container ref={containerRef} className={className}>
      <div
        style={{
          position: 'absolute',
          cursor: 'pointer',
          right: '12px',
          top: '15px',
          width: '28px',
          height: '28px',
        }}
        onClick={onClose}
      >
        <Close width={28}/>
      </div>
      {children}
    </Container>
  </Overlay>
}
