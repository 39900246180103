import {ReactNode} from "react";
import styled from "styled-components";
import {Popover, PopoverContent} from "../popover";

const Container = styled.div`
    height: 24px;
    min-width: 54px;
    background: rgba(23, 33, 43, 0.8);
    display: flex;
    align-items: center;
    gap: 3px;
    border-radius: 999px;
    padding-right: 8px;
    
    color: white;
    font-family: Oswald;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
`

const Icon = styled.div<{ $iconUrl: string }>`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    
    background-image: url(${props => props.$iconUrl});
    background-size: cover;
    background-position: center;
`

const StyledPopoverContent = styled(PopoverContent)`
  && {
      left: 0;
      bottom: 8px;
      width: 300px;
      // to be on top of relic-box chest
      z-index: 100000 !important;
      
      
      // remove arrow
      &:before {
          display: none;
      }
  }
`

type Props = {
  children: ReactNode;
  popoverText: string;
  iconUrl: string;
}

export const BoostLabel = ({ children, popoverText, iconUrl }: Props) => {

  return <Popover>
    <Container>
      <Icon $iconUrl={iconUrl} />
      {children}
    </Container>

    <StyledPopoverContent>
      {popoverText}
    </StyledPopoverContent>
  </Popover>
}
