import styled, {css} from "styled-components";
import {UnstyledButton} from "../unstyled-button";

const commonGreenAndBlueButtonStyles = css`
    border: 2px solid #000000;
    box-shadow: 0px 0px 0px 2px #FFFFFF inset;
    font-family: 'Oswald';
    font-weight: 600;
    text-align: center;
    background: #FFFFFF;
    text-transform: uppercase;
    text-shadow: 0 0.75px 1.5px rgba(0,0,0,0.3);
    color: white;
`

const getBlueButtonStyles = (props: { $shine?: boolean, $size: ButtonSize }) => css`
    ${commonGreenAndBlueButtonStyles};
    background-color: #2F86B3;
    
    ${props.$shine && css`
        box-shadow: 0px 4px 36px 2px #2F86B3B2, 0px 0px 0px 2px #FFFFFF inset;
    `}
    
    ${props.$size === 'xl' && css`
        font-weight: 500;
        text-shadow: none;
    `}
`

const goldButtonStyles = css`
  ${commonGreenAndBlueButtonStyles};
  background: linear-gradient(270deg, #b37804 0%, #b89707 100%);
`;

const getGreenButtonStyles = (props: { $shine?: boolean, $size: ButtonSize }) => css`
    ${commonGreenAndBlueButtonStyles};
    background-color: #58994E;

    ${props.$shine && css`
        box-shadow: 0px 4px 36px 2px #58994EB2, 0px 0px 0px 2px #FFFFFF inset;
    `}

    ${props.$size === 'xl' && css`
        font-weight: 500;
        text-shadow: none;
    `}
`

const blackButtonStyles = css`
    background-color: #17212B;
    border: 2px solid rgba(255, 255, 255, 0.16);
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
`

type ButtonSize = 'xs' | 's' | 'm' | 'xl'

const getSizeStyles = (size: ButtonSize) => {
  switch (size) {
    case 'xs':
      return css`
        padding: 0 12px;
        height: 30px;
        font-size: 11px;
      `;
    case 's':
      return css`
        padding: 6px 14px;
        height: 36px;
        font-size: 14px;
      `;
    case 'm':
      return css`
        padding: 6px 14px;
        height: 40px;
        font-size: 14px;
        line-height: 20.75px;
      `;
    case 'xl':
      return css`
        padding: 8px 16px;
        height: 54px;
        border-radius: 30px;
        font-size:22px;
        font-weight: 500;
          
      `;
  }

}

type ButtonProps = {
  $variant?: 'primary' | 'secondary' | 'green' | 'blue' | 'black' | 'gold'
  $size?: ButtonSize;
  $shine?: boolean;
  disabled?: boolean;
}

export const Button = styled(UnstyledButton)<ButtonProps>`
    box-sizing: border-box;
    border-radius: 999px;
    
    ${props => getSizeStyles(props.$size || 'm')};
    
    ${props => props.$variant === 'primary' && ''};
    ${props => props.$variant === 'secondary' && ''};
    ${props => props.$variant === 'green' && getGreenButtonStyles({ $shine: props.$shine, $size: props.$size || 'm' })};
    ${props => props.$variant === 'blue' && getBlueButtonStyles({ $shine: props.$shine, $size: props.$size || 'm' })};
    ${props => props.$variant === 'black' && blackButtonStyles};
    ${props => props.$variant === 'gold' && goldButtonStyles};
    
    ${props => props.disabled && css`
        pointer-events: none;
        cursor: default;
    `}
`
